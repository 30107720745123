<template>
    <div class="group-bg-box">
        <div class="newword-security-box">
            <div class="network-security-pc-titles w-33p" v-for="(item,index) in items" :key="index"  @click="itemsClick(index)">
                {{item.name}}
                <div class="network-security-pc-titles-active" v-if="index == itemsActive"></div>
            </div>
        </div>

        <div v-show="itemsActive == 0">
            <div class="about-box d-flex d-flex-between">
                <div class="about-item-box w-40p">
                    <div class="about-item-group">工商服务</div>
                    <div class="about-item-swipers p-25">
                        <img src="@/assets/image/company/network-security/bg.png" class="w-100p">
                    </div>
                </div>
                <div class="about-item-box w-58p">
                    <div class="about-item-group-title">
                        工商服务介绍
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="about-item-group-desc">
                        <div>
                          为便捷企业，孵化器提供一站式工商服务，快捷、低成本！竭诚办理：1、工商注册：公司名称核准、提供集中办公区注册地址、起草公司章程、领取营业执照、公章登记备案办理、办理税务登记证、银行开户许可证等。 2、公司变更，注销，转让服务 3、资质代办：食品流通/经营许可证，卫生许可证、科技企业认定、高新企业认定等许可证办理业务
税务服务、资质许可、知识产权
代理记账内容：基础服务（每月整理原始票据、建账、财务核算、出具财务报表、每月纳税申报、日常基础财务，税务咨询、月度装订凭证、年度装订账簿、财税风险预警）赠送服务（年度企业所得税清缴、企业年度工商年检、每月报税提醒、办理季度实时掌握、免费财税咨询和终身售后指导）。

                        </div>
                    </div>
                </div>
            </div>
            <div class="about-box m-t-20">
                <div class="w-100p">
                    <div class="about-item-group-title">
                        我们的工商服务
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="p-20 d-flex d-flex-around d-flex d-flex-wrap d-flex-between">
                        <div class="newword-security-characteristic-pc-box w-24p d-flex d-flex-items-center d-flex-between p-t-1p p-b-1p m-b-1p"
                        v-for="(item,index) in 16" :key="index">
                            <div class="w-60p m-l-5p color-white">
                                <div class="p-b-25 f-26">公司注册</div>
                                <div class="f-14">满意为止·无隐形消费</div>
                            </div>
                            <img src="@/assets/image/company/finance/icon-1.png" class="w-30p display-block m-r-5p">
                        </div>
                    </div>
                </div>
            </div>
            <div class="about-box m-t-20">
                <div class="w-100p">
                    <div class="about-item-group-title">
                        服务全程无忧
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="d-flex">
                        <div class="network-security-yx-box w-15p p-t-2p p-b-2p m-t-10p h-150">
                            <div class="network-security-yx-title1 align-center">一对一顾问</div>
                            <div class="color-white m-t-20 w-80p m-l-10p align-center">专业化流程节点一对一专属顾问</div>
                        </div>
                        <div class="network-security-yx-box w-15p p-t-2p p-b-2p m-t-5p h-150">
                            <div class="network-security-yx-title1 align-center">一对一顾问</div>
                            <div class="color-white m-t-20 w-80p m-l-10p align-center">专业化流程节点一对一专属顾问</div>
                        </div>
                        <div class="network-security-yx-box w-15p p-t-2p p-b-2p h-150">
                            <div class="network-security-yx-title1 align-center">一对一顾问</div>
                            <div class="color-white m-t-20 w-80p m-l-10p align-center">专业化流程节点一对一专属顾问</div>
                        </div>
                        <div class="network-security-yx-box w-15p p-t-2p p-b-2p m-t-5p h-150">
                            <div class="network-security-yx-title1 align-center">一对一顾问</div>
                            <div class="color-white m-t-20 w-80p m-l-10p align-center">专业化流程节点一对一专属顾问</div>
                        </div>
                        <div class="network-security-yx-box w-15p p-t-2p p-b-2p m-t-10p h-150">
                            <div class="network-security-yx-title1 align-center">一对一顾问</div>
                            <div class="color-white m-t-20 w-80p m-l-10p align-center">专业化流程节点一对一专属顾问</div>
                        </div>
                    </div>
                    <img src="@/assets/image/company/finance/bg-1.png" class="w-100p display-block">
                </div>
            </div>
            <div class="about-box m-t-20">
                <div class="w-100p">
                    <div class="about-item-group-title">
                        设计流程
                        <div class="about-item-group-dir"></div>
                    </div>
                    <img src="@/assets/image/company/finance/bg-2.png" class="w-90p m-l-5p">
                </div>
            </div>
        </div>
        <!-- 1 -->
        <div v-show="itemsActive == 1">
            <div class="about-box d-flex d-flex-between">
                <div class="about-item-box w-40p">
                    <div class="about-item-group">税务服务</div>
                    <div class="about-item-swipers p-25">
                        <img src="@/assets/image/company/network-security/bg2.png" class="w-100p">
                    </div>
                </div>
                <div class="about-item-box w-58p">
                    <div class="about-item-group-title">
                        税务服务介绍
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="about-item-group-desc">
                        <div>
                           为便捷企业，孵化器提供一站式工商服务，快捷、低成本！竭诚办理：1、工商注册：公司名称核准、提供集中办公区注册地址、起草公司章程、领取营业执照、公章登记备案办理、办理税务登记证、银行开户许可证等。 2、公司变更，注销，转让服务 3、资质代办：食品流通/经营许可证，卫生许可证、科技企业认定、高新企业认定等许可证办理业务

                        </div>
                    </div>
                </div>
            </div>
            <div class="about-box m-t-20">
                <div class="w-100p">
                    <div class="about-item-group-title">
                        我们的税务服务
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="p-20 d-flex d-flex-around d-flex d-flex-wrap d-flex-between">
                        <div class="newword-security-characteristic-pc-box w-24p p-b-1p m-b-1p"
                        v-for="(item,index) in 16" :key="index">
                            <img src="@/assets/image/company/finance/icon-2.png" class="w-100p display-block br-20">
                            <div class="w-60p m-l-5p color-white">
                                <div class="p-t-20 p-b-15 f-22">小规模0申报记账</div>
                                <div class="f-14">建立账册、编制报表、每月申报纳税、编制凭证、代开发票、认证发票、工商年报、汇算清缴</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="about-box m-t-20">
                <div class="w-100p">
                    <div class="about-item-group-title">
                        代理记账内容
                        <div class="about-item-group-dir"></div>
                    </div>
                    <img src="@/assets/image/company/finance/icon-3.png" class="w-90p m-l-5p">
                </div>
            </div>
            <div class="about-box m-t-20">
                <div class="w-100p">
                    <div class="about-item-group-title">
                        不记账报税有什么后果
                        <div class="about-item-group-dir"></div>
                    </div>
                    <img src="@/assets/image/company/finance/icon-4.png" class="w-90p m-l-5p">
                </div>
            </div>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent,ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
    setup(){
        const itemsActive:any = ref(0)
        const items:any = ref([
            { name:"工商服务" },
            { name:"税务服务" },
            { name:"资质许可" },
            { name:"知识产权" },
        ])
        const itemsClick = (key:any)=>{
            itemsActive.value = key
        }
        return{
            items,itemsActive,itemsClick
        }
    }
});
</script>


<style>
.el-carousel__item--card.is-in-stage.is-hover .el-carousel__mask, .el-carousel__item--card.is-in-stage:hover .el-carousel__mask {
    opacity: 1 !important;
}
.el-carousel__mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
     background:none !important;
    opacity: .24;
    transition: var(--el-transition-duration-fast);
}
</style>
