
import { defineComponent,ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
    setup(){
        const itemsActive:any = ref(0)
        const items:any = ref([
            { name:"工商服务" },
            { name:"税务服务" },
            { name:"知识产权" },
            { name:"广告传媒" },
        ])
        const itemsClick = (key:any)=>{
            itemsActive.value = key
        }
        return{
            items,itemsActive,itemsClick
        }
    }
});
