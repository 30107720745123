<template>
    <div class="group-bg-box">
         <div class="newword-security-box w-100p lh-60 h-60">
            <div class="network-security-pc-titles f-12 w-33p lh-60 h-60" v-for="(item,index) in items" :key="index"  @click="itemsClick(index)">
                {{item.name}}
                <div class="network-security-pc-titles-active" v-if="index == itemsActive"></div>
            </div>
        </div>
        <div v-if="itemsActive == 0">
            <div class=" ">
                <div class="about-item-box w-100p">
                    <div class="about-item-group">工商服务</div>
                    <div class="about-item-swipers p-25">
                        <img src="@/assets/image/company/network-security/bg.png" class="w-100p">
                    </div>
                </div>
                <div class="about-item-box w-100p m-t-20">
                    <div class="about-item-group-title">
                        工商服务介绍
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="about-item-group-desc">
                        <div>
                           为便捷企业，孵化器提供一站式工商服务，快捷、低成本！竭诚办理：1、工商注册：公司名称核准、提供集中办公区注册地址、起草公司章程、领取营业执照、公章登记备案办理、办理税务登记证、银行开户许可证等。 2、公司变更，注销，转让服务 3、资质代办：食品流通/经营许可证，卫生许可证、科技企业认定、高新企业认定等许可证办理业务
税务服务、资质许可、知识产权
代理记账内容：基础服务（每月整理原始票据、建账、财务核算、出具财务报表、每月纳税申报、日常基础财务，税务咨询、月度装订凭证、年度装订账簿、财税风险预警）赠送服务（年度企业所得税清缴、企业年度工商年检、每月报税提醒、办理季度实时掌握、免费财税咨询和终身售后指导）。

                        </div>
                    </div>
                </div>
            </div>
            <div class="m-t-20">
                <div class="about-item-box w-100p">
                    <div class="about-item-group-title">
                        我们的工商服务
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="p-5">
                        <div class="newword-security-characteristic-pc-box w-100p m-b-15" v-for="(item,index) in 6" :key="index">
                            <img src="@/assets/image/company/finance/icon-1.png" class="w-30p m-l-35p m-t-5p m-b-5p">
                            <div class="w-80p m-l-10p color-white p-b-25">
                                <div class="align-center p-b-25 f-26">公司注册</div>
                                <div class="f-14 align-center">满意为止·无隐形消费</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="m-t-20">
                <div class="about-item-box w-100p">
                    <div class="about-item-group-title">
                        服务流程
                        <div class="about-item-group-dir"></div>
                    </div>
                   <img src="@/assets/image/company/finance/bg-3.png" class="w-100p display-block">
                </div>
            </div>
        </div>
        <div v-if="itemsActive == 1">
            <div class=" ">
                <div class="about-item-box w-100p">
                    <div class="about-item-group">速成建站</div>
                    <div class="about-item-swipers p-25">
                        <img src="@/assets/image/company/network-security/bg2.png" class="w-100p">
                    </div>
                </div>
                <div class="about-item-box w-100p m-t-20">
                    <div class="about-item-group-title">
                        速成建站
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="about-item-group-desc">
                        <div>
                            可根据客户的需求，量身定制一系列符合客户实际应用的软件。 目前的多数信息管理软件可以满足行业内的通用需求,而在特殊流程和客户定制.上成本很高。 对于中小企业来说,可以根据自己的实际业务需求,定制开发一-套适合自己的软件。 开发不求一步到位，一步-步进行完善。如此，可以减少投入,并非常适合自身业务发展。
软件定制主要是指企业管理软件的定制,企业管理软件是指能够体现企业管理的大部分职能(包括决策、计划、组织、领导、 监控、分析等等) ,能够提供实时、相关、准确、完整的数据,为管理者提供决策依据的一种软件。以模块划分,企业管理软件可分为财务管理进销存管理(ERP)、人力资源管理(HR)、供应链管理( SCM)、客户关系管理( CRM )等品种。
                        </div>
                    </div>
                </div>
            </div>
             <div class="m-t-20">
                <div class="about-item-box w-100p">
                    <div class="about-item-group-title">
                        技术服务内容
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="p-5">
                        <div class="align-center">
                            <div class="network-security-yx-title1 m-t-2p">[多站合一，多端覆盖]</div>
                            <div class="network-security-yx-title2 m-t-10 m-b-2p w-90p m-l-5p f-12">pc，手机，小程序等多端 访问</div>
                        </div>
                        <div class="align-center m-t-5p">
                            <div class="network-security-yx-title1 m-t-2p">[轻松维护，快速更新 ] </div>
                            <div class="network-security-yx-title2 m-t-10 m-b-2p w-90p m-l-5p f-12">可视化后台，一键发布</div>
                        </div>
                        <div class="align-center m-t-5p">
                            <div class="network-security-yx-title1 m-t-2p">[海量行业风格任选] </div>
                            <div class="network-security-yx-title2 m-t-10 m-b-2p w-90p m-l-5p f-12">选择模版风格，马上建站</div>
                        </div>
                        <div class="align-center m-t-5p">
                            <div class="network-security-yx-title1 m-t-2p">[云服务器] </div>
                            <div class="network-security-yx-title2 m-t-10 m-b-2p w-90p m-l-5p f-12">稳定安全，访问快速</div>
                        </div>
                        <div class="align-center m-t-5p">
                            <div class="network-security-yx-title1 m-t-2p">[在线客服]  </div>
                            <div class="network-security-yx-title2 m-t-10 m-b-2p w-90p m-l-5p f-12">实时交流，提升获客转化率</div>
                        </div>
                        <div class="align-center m-t-5p">
                            <div class="network-security-yx-title1 m-t-2p">[多语言支持]  </div>
                            <div class="network-security-yx-title2 m-t-10 m-b-2p w-90p m-l-5p f-12">面向不同客户群体</div>
                        </div>
                        <div class="align-center m-t-5p">
                            <div class="network-security-yx-title1 m-t-2p">[表单系统] </div>
                            <div class="network-security-yx-title2 m-t-10 m-b-2p w-90p m-l-5p f-12">活动报名、投票、招聘等</div>
                        </div>
                        <div class="align-center m-t-5p">
                            <div class="network-security-yx-title1 m-t-2p">[站点权限管理] </div>
                            <div class="network-security-yx-title2 m-t-10 m-b-2p w-90p m-l-5p f-12">指定内部人员编辑权限</div>
                        </div>
                        <div class="align-center m-t-5p">
                            <div class="network-security-yx-title1 m-t-2p">[网站备份]  </div>
                            <div class="network-security-yx-title2 m-t-10 m-b-2p w-90p m-l-5p f-12">随时备份避免操作失误</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="about-box m-t-20">
                <div class="w-100p">
                    <div class="about-item-group-title">
                        建站开发流程
                        <div class="about-item-group-dir"></div>
                    </div>
                    <img src="@/assets/image/company/network-security/bg4.png" class="w-90p m-l-5p">
                </div>
            </div>
        </div>
        <div class="w-100p h-180"></div>
    </div>
</template>
<script lang='ts'>
import { defineComponent,ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
    setup(){
        const itemsActive:any = ref(0)
        const items:any = ref([
            { name:"工商服务" },
            { name:"税务服务" },
            { name:"知识产权" },
            { name:"广告传媒" },
        ])
        const itemsClick = (key:any)=>{
            itemsActive.value = key
        }
        return{
            items,itemsActive,itemsClick
        }
    }
});
</script>
<style>
.el-carousel__item--card.is-in-stage.is-hover .el-carousel__mask, .el-carousel__item--card.is-in-stage:hover .el-carousel__mask {
    opacity: 1 !important;
}
.el-carousel__mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background:none !important;
    opacity: .24;
    transition: var(--el-transition-duration-fast);
}
</style>
